import { ChangeEventHandler } from 'react'

export function Input(props: {
  name: string
  value: string
  onChange: ChangeEventHandler<HTMLInputElement>
}) {
  const { name, value, onChange } = props

  return (
    <input
      name={name}
      className="border rounded py-1 px-2 mx-1 my-2 border-gray-300 w-10 flex-1"
      onChange={onChange}
      value={value}
    />
  )
}
