import {
  Button,
  Card,
  CardBody,
  Input,
  Typography,
} from '@material-tailwind/react'
import { FormEvent, useState } from 'react'
import { useMeStore } from '../stores/MeStore'
import { familyAPI } from '../utils/API'
import { isAxiosError } from 'axios'
import { AxiosErrorRespone } from '../interfaces/AxiosErrorResponse'

export default function Login() {
  const [state, setState] = useState({
    name: '',
    nameError: false,
    password: '',
    passwordError: false,
  })
  const { login } = useMeStore((state) => state)

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { name, password } = state
    if (name && password) {
      try {
        const res = await familyAPI.post(`/admin/login`, {
          name: name,
          password: password,
        })

        const { accessToken, refreshToken } = res.data

        localStorage.setItem('accessToken', accessToken)
        localStorage.setItem('refreshToken', refreshToken)

        login(accessToken)
      } catch (error) {
        if (isAxiosError<AxiosErrorRespone>(error)) {
          alert(error.response?.data.message)
        }
      }
    }
  }

  return (
    <div className="bg-cyan-900 h-screen w-full flex justify-center items-center">
      <Card className="w-80">
        <CardBody>
          <Typography
            variant="h3"
            color="blue-gray"
            className="text-center p-2 font-black"
          >
            행산원 관리센터
          </Typography>
          <Typography
            variant="h5"
            color="blue-gray"
            className="text-center p-1 mb-5 font-black"
          >
            로그인
          </Typography>
          <form className="flex flex-col space-y-5" onSubmit={handleSubmit}>
            <Input
              label="ID"
              autoFocus
              value={state.name}
              onChange={(e) => {
                setState({ ...state, name: e.target.value })
              }}
              onBlur={(e) => {
                setState({ ...state, nameError: !e.target.value })
              }}
              error={state.nameError}
            />
            <Input
              label="비밀번호"
              type="password"
              value={state.password}
              onChange={(e) => {
                setState({ ...state, password: e.target.value })
              }}
              onBlur={(e) => {
                setState({ ...state, passwordError: !e.target.value })
              }}
              error={state.passwordError}
            />
            <Button type="submit">로그인</Button>
          </form>
        </CardBody>
      </Card>
    </div>
  )
}
