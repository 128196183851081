import { FormEvent, MouseEvent, useState } from 'react'
import { UserStatus } from '../../interfaces/User'
import { familyAPI } from '../../utils/API'
import { Button, Input } from '@material-tailwind/react'

export interface EditUser {
  id?: number
  pid?: number
  name: string
  phoneNumber: string
  expiredAt: string
  status: UserStatus
}

export function HandleUser({
  user,
  onSubmitUser,
  rejectable,
  onReject,
}: {
  user: EditUser
  onSubmitUser: (user: EditUser) => void
  rejectable?: boolean
  onReject?: () => void
}) {
  const [userInfo, setUserInfo] = useState<EditUser>({ ...user })
  console.log(user)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    let value: string | number = e.target.value

    if (name === 'pid')
      if (isNaN(Number(value))) return
      else value = +value

    setUserInfo({ ...userInfo, [name]: value })
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onSubmitUser(userInfo)
  }

  const handleBlock = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    await familyAPI.patch(`/admin/users/${user.id}/block`)
    window.location.reload()
  }

  const handleUnblock = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    await familyAPI.patch(`/admin/users/${user.id}/unblock`)
    window.location.reload()
  }

  return (
    <div>
      <form onSubmit={handleSubmit} className="space-y-3 w-72 text-right">
        <Input
          label="PID"
          value={userInfo.pid}
          name="pid"
          onChange={handleInputChange}
        />
        <div className="flex space-x-1">
          <Input
            label="이름"
            value={userInfo.name}
            name="name"
            required
            onChange={handleInputChange}
          />
          {user.id ? (
            user.status === UserStatus.BLOCKED ? (
              <Button className="w-20 bg-cyan-900" onClick={handleUnblock}>
                재입회
              </Button>
            ) : (
              <Button className="w-20 bg-red-900" onClick={handleBlock}>
                차단
              </Button>
            )
          ) : null}
        </div>
        <Input
          label="전화번호"
          value={userInfo.phoneNumber}
          name="phoneNumber"
          required
          pattern="^010[0-9]{8}"
          onChange={handleInputChange}
        />
        <Input
          label="만료일"
          value={userInfo.expiredAt}
          name="expiredAt"
          pattern="^[0-9]{6}"
          onChange={handleInputChange}
        />
        {rejectable ? (
          <div className="flex space-x-1">
            <Button className="w-full bg-cyan-900" type="submit">
              수락
            </Button>
            <Button
              className="w-full bg-red-900"
              onClick={() => {
                onReject && onReject()
              }}
            >
              거절
            </Button>
          </div>
        ) : (
          <Button className="w-full bg-cyan-900" type="submit">
            확인
          </Button>
        )}
      </form>
    </div>
  )
}
