import { useEffect, useState } from 'react'
import { familyAPI } from '../utils/API'
import { Menu } from '../interfaces/Menu'
import { Link } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Input,
  Switch,
} from '@material-tailwind/react'
import { isAxiosError } from 'axios'
import { AxiosErrorRespone } from '../interfaces/AxiosErrorResponse'

export function ManageMenu() {
  const [menu, setMenu] = useState<Menu[]>([])
  const [isAddMenu, setIsAddMenu] = useState<boolean>(false)
  const [newMenuName, setNewMenuName] = useState('')
  const [dialog, setDialog] = useState({
    isOpen: false,
    header: '',
    message: '',
    function: () => {},
  })

  useEffect(() => {
    fetchMenus()
  }, [])

  const fetchMenus = async () => {
    const res = await familyAPI.get<Menu[]>('/admin/menus')
    setMenu(res.data)
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const res = await familyAPI.post('/admin/menus', {
      name: newMenuName,
      order: menu.length,
    })

    if (res.status === 201) {
      setNewMenuName('')
      setIsAddMenu(false)
      fetchMenus()
    }
  }

  const deleteMenu = async (id: number) => {
    const res = await familyAPI.delete(`/admin/menus/${id}`)

    if (res.status === 200) fetchMenus()
  }

  const deletePage = async (id: number) => {
    const res = await familyAPI.delete(`/admin/pages/${id}`)

    if (res.status === 200) fetchMenus()
  }

  const toggleMenuPublish = async (id: number, isPublished: boolean) => {
    try {
      await familyAPI.patch(`/admin/menus/${id}`, {
        isPublished: isPublished,
      })

      await fetchMenus()
    } catch (error) {
      if (isAxiosError<AxiosErrorRespone>(error)) {
        alert(error.response?.data.message)
      }
    }
  }

  const handleMenuUp = async (id: number) => {
    await familyAPI.patch(`/admin/menus/${id}/up`)
    fetchMenus()
  }

  const handlePageUp = async (id: number) => {
    await familyAPI.patch(`/admin/pages/${id}/up`)
    fetchMenus()
  }

  return (
    <>
      <Dialog
        open={dialog.isOpen}
        handler={() => {
          setDialog({ ...dialog, isOpen: false })
        }}
      >
        <DialogHeader>{dialog.header}</DialogHeader>
        <DialogBody className="text-black font-normal">
          {dialog.message}
        </DialogBody>
        <DialogFooter className="space-x-1">
          <Button
            onClick={() => {
              dialog.function()
              setDialog({ ...dialog, isOpen: false })
            }}
          >
            확인
          </Button>
          <Button
            color="red"
            onClick={() => {
              setDialog({ ...dialog, isOpen: false })
            }}
          >
            취소
          </Button>
        </DialogFooter>
      </Dialog>
      <Card className="m-3">
        <CardBody>
          {menu.map((menuItem) => {
            return (
              <div key={menuItem.id}>
                <div className="m-1 p-2 w-80 border border-gray-500 rounded-lg flex justify-between items-center">
                  <div className="flex space-x-6 items-center">
                    <div>{menuItem.name}</div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <div className="flex space-x-3">
                      <div
                        className={`font-bold ${
                          menuItem.isPublished
                            ? 'text-green-500'
                            : 'text-red-500'
                        }`}
                      >
                        {menuItem.isPublished ? '공개' : '비공개'}
                      </div>
                      <Switch
                        checked={menuItem.isPublished}
                        onChange={() => {
                          toggleMenuPublish(menuItem.id, !menuItem.isPublished)
                        }}
                      />
                    </div>
                    {menuItem.order > 0 ? (
                      <Button
                        className="py-1 px-3 bg-cyan-900"
                        onClick={async () => {
                          handleMenuUp(menuItem.id)
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75"
                          />
                        </svg>
                      </Button>
                    ) : null}
                    <Button
                      className="bg-red-500"
                      onClick={() => {
                        setDialog({
                          isOpen: true,
                          header: `${menuItem.name} 삭제`,
                          message: `${menuItem.name}을 삭제하시겠습니까?`,
                          function: () => {
                            deleteMenu(menuItem.id)
                          },
                        })
                      }}
                    >
                      삭제
                    </Button>
                  </div>
                </div>
                {menuItem.pages.map((page) => (
                  <div
                    key={page.id}
                    className="m-1 ml-10 w-80 hover:bg-cyan-50"
                  >
                    <Link to={`/pages/${page.href}/manage`} key={page.href}>
                      <div className="p-2 border border-gray-500 rounded-lg flex justify-between items-center">
                        <div className="flex space-x-6 items-center">
                          <div>{page.name}</div>
                        </div>
                        <div className="flex items-center space-x-2">
                          <div
                            className={`font-bold ${
                              page.isPublished
                                ? 'text-green-500'
                                : 'text-red-500'
                            }`}
                          >
                            {page.isPublished ? '공개' : '비공개'}
                          </div>
                          {page.order > 0 && (
                            <Button
                              className="py-1 px-3 bg-cyan-900"
                              onClick={async (e) => {
                                e.preventDefault()
                                handlePageUp(page.id)
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75"
                                />
                              </svg>
                            </Button>
                          )}
                          {page.isEditable && (
                            <Button
                              className="bg-red-500"
                              onClick={(e) => {
                                e.preventDefault()
                                setDialog({
                                  isOpen: true,
                                  header: `${page.name} 삭제`,
                                  message: `${page.name}을 삭제하시겠습니까?`,
                                  function: () => {
                                    deletePage(page.id)
                                  },
                                })
                              }}
                            >
                              삭제
                            </Button>
                          )}
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
                <div className="m-1 ml-10 p-2 w-80 border border-gray-500 rounded-lg hover:bg-cyan-50 cursor-pointer">
                  <Link
                    to={`/menus/${menuItem.id}/${menuItem.pages.length}/new-page`}
                  >
                    + 페이지 추가
                  </Link>
                </div>
              </div>
            )
          })}
          <div className="m-1 w-60 border border-gray-500 rounded-lg">
            {isAddMenu ? (
              <form className="p-2" onSubmit={handleSubmit}>
                <div className="m-3 flex-1">
                  <Input
                    autoFocus
                    label="메뉴 이름"
                    required
                    value={newMenuName}
                    onChange={(e) => setNewMenuName(e.target.value)}
                  />
                </div>
                <div className="mx-3 flex justify-end">
                  <Button type="submit">추가</Button>
                  <Button
                    type="button"
                    onClick={() => setIsAddMenu(false)}
                    color="red"
                  >
                    취소
                  </Button>
                </div>
              </form>
            ) : (
              <div
                className="cursor-pointer hover:bg-cyan-50 p-2 rounded-lg"
                onClick={() => setIsAddMenu(true)}
              >
                + 메뉴 추가
              </div>
            )}
          </div>
        </CardBody>
      </Card>
    </>
  )
}
