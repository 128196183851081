import { CKEditor } from '@ckeditor/ckeditor5-react'
import { ClassicEditor } from 'ckeditor5'
import './PageEditor.css'
import { Page } from '../../interfaces/Page'
import { useRef, useState } from 'react'
import { familyAPI } from '../../utils/API'
import { Button, Input, Switch } from '@material-tailwind/react'
import { editorConfig } from './CKConfig'

export function PageEditor({ page }: { page: Page }) {
  const [currentPage, setCurrentPage] = useState(page)
  const bannerInputRef = useRef<HTMLInputElement | null>(null)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPage({ ...currentPage, [e.target.name]: e.target.value })
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      if (currentPage.id) {
        await familyAPI.patch(`/admin/pages/${currentPage.id}`, currentPage)
      } else {
        await familyAPI.post('/admin/pages', currentPage)
      }
      window.location.replace('/menus/manage')
    } catch {
      console.log('api fail')
    }
  }
  return (
    <div>
      <form className="h-full" onSubmit={handleSubmit}>
        <div className="flex p-3 space-x-3">
          <div className="flex-1">
            <Input
              autoFocus
              label="이름"
              required
              value={currentPage.name}
              name="name"
              onChange={handleChange}
            />
          </div>
          <div className="flex-1">
            <Input
              autoFocus
              label="패스"
              required
              value={currentPage.href}
              name="href"
              onChange={handleChange}
              disabled={!currentPage.isEditable}
            />
          </div>
          <div className="flex items-center space-x-3">
            <div>공개</div>
            <Switch
              checked={currentPage.isPublished}
              onChange={() => {
                console.log(currentPage)
                setCurrentPage({
                  ...currentPage,
                  isPublished: !currentPage.isPublished,
                })
              }}
            />
          </div>
        </div>
        <div className="px-3 mb-3 flex space-x-1">
          <Input
            label="Title"
            name="title"
            onChange={handleChange}
            value={currentPage.title}
          />
          <Input
            label="Sub title"
            name="subTitle"
            onChange={handleChange}
            value={currentPage.subTitle}
          />
          <Input
            label="Title font color"
            name="titleColor"
            onChange={handleChange}
            value={currentPage.titleColor}
          />
        </div>
        <div className="px-3 flex justify-between">
          <div className="flex space-x-1">
            <Input
              label="rgb"
              name="bannerBackgroundColor"
              onChange={handleChange}
              value={currentPage.bannerBackgroundColor}
            />
            <div className="w-full p-2 ">
              {currentPage.bannerImage ? (
                <a
                  className="underline text-blue-500"
                  href={currentPage.bannerImage}
                  target="_blank"
                  rel="noreferrer"
                >
                  {currentPage.bannerImage}
                </a>
              ) : (
                '배너 없음'
              )}
            </div>
          </div>
          <div className="space-x-2">
            <input
              className="w-0 overflow-hidden"
              type="file"
              accept="image/*"
              id="bannerImage"
              ref={bannerInputRef}
              onChange={async (e) => {
                if (e.target.files && e.target.files[0]) {
                  const formData = new FormData()
                  formData.append('file', e.target.files[0])
                  const res = await familyAPI.post('/admin/images', formData, {
                    headers: {
                      'Content-Type': 'multipart/form-data',
                    },
                  })

                  setCurrentPage({ ...currentPage, bannerImage: res.data.url })
                }
              }}
            />
            <Button
              onClick={() => {
                bannerInputRef.current?.click()
              }}
            >
              배너 등록
            </Button>
            <Button
              className="bg-red-500"
              onClick={() => {
                setCurrentPage({
                  ...currentPage,
                  bannerBackgroundColor: '',
                  bannerImage: null,
                })
              }}
            >
              배너 제거
            </Button>
          </div>
        </div>
        <div>{}</div>
        {currentPage.isEditable && (
          <div className="pb-8 m-3 no-tailwindcss-base">
            <CKEditor
              editor={ClassicEditor}
              data={currentPage.contents}
              config={editorConfig}
              onChange={(event, editor) => {
                console.log(editor.data.get())
                setCurrentPage({ ...currentPage, contents: editor.data.get() })
              }}
            />
          </div>
        )}
        <div className="flex justify-end m-3">
          <Button type="submit">저장</Button>
        </div>
      </form>
    </div>
  )
}
