import { Button, Card, CardFooter, IconButton } from '@material-tailwind/react'
import { useCallback, useEffect, useState } from 'react'
import { PediStatus, Register } from '../interfaces/Register'
import { useModalStore } from '../stores/ModalStore'
import { familyAPI } from '../utils/API'
import { formatDateTime } from '../utils/TimeFormatter'
import { EditUser, HandleUser } from '../components/modal/HandleUser'
import { UserStatus } from '../interfaces/User'

export default function ManageRegister() {
  const [data, setData] = useState<{
    registers: Register[]
    startPage: number
    endPage: number
  }>({ registers: [], startPage: 0, endPage: 0 })
  const [pageInfo, setPageInfo] = useState({
    page: 0,
    size: 10,
  })

  const fetchRegisters = useCallback(async () => {
    const { page, size } = pageInfo
    const res = await familyAPI.get('/admin/registers', {
      params: {
        page: page,
        size: size,
      },
    })

    const startPage = Math.max(page - 2, 0)
    const endPage = Math.floor(Math.min(startPage + 4, res.data.total / size))

    setData({
      registers: res.data.items,
      startPage: startPage,
      endPage: endPage,
    })
  }, [pageInfo])

  useEffect(() => {
    fetchRegisters()
  }, [fetchRegisters])

  const pagination = () => {
    const arr: Array<JSX.Element> = []
    for (let i = data.startPage; i <= data.endPage; i++) {
      const variant = i === pageInfo.page ? 'filled' : 'text'
      arr.push(
        <IconButton
          key={i}
          variant={variant}
          onClick={() => {
            setPageInfo({ ...pageInfo, page: i })
          }}
        >
          {i + 1}
        </IconButton>
      )
    }
    return arr
  }

  return (
    <Card className="m-2">
      <div className="mb-3 p-4">
        <ListHeader />
        {data.registers.map((register) => (
          <RegisterListItem
            key={register.id}
            register={register}
            fetchRegisters={fetchRegisters}
          />
        ))}
      </div>
      <CardFooter className="flex justify-center">
        <div className="flex items-center gap-2">
          <Button
            variant="text"
            className="flex items-center"
            onClick={() => setPageInfo({ ...pageInfo, page: data.startPage })}
            disabled={pageInfo.page === 0}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-5 h-5"
            >
              <path
                fillRule="evenodd"
                d="M15.79 14.77a.75.75 0 01-1.06.02l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 111.04 1.08L11.832 10l3.938 3.71a.75.75 0 01.02 1.06zm-6 0a.75.75 0 01-1.06.02l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 111.04 1.08L5.832 10l3.938 3.71a.75.75 0 01.02 1.06z"
                clipRule="evenodd"
              />
            </svg>
          </Button>
          <div className="flex items-center gap-1">{pagination()}</div>
          <Button
            variant="text"
            className="flex items-center"
            onClick={() => setPageInfo({ ...pageInfo, page: data.endPage })}
            disabled={pageInfo.page === data.endPage}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-5 h-5"
            >
              <path
                fillRule="evenodd"
                d="M10.21 14.77a.75.75 0 01.02-1.06L14.168 10 10.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                clipRule="evenodd"
              />
              <path
                fillRule="evenodd"
                d="M4.21 14.77a.75.75 0 01.02-1.06L8.168 10 4.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                clipRule="evenodd"
              />
            </svg>
          </Button>
        </div>
      </CardFooter>
    </Card>
  )
}

function ListHeader() {
  return (
    <div className="border-black border border-b-0 last:border-b last:rounded-b-md rounded-t-md bg-gray-300 font-bold">
      <div className="flex">
        <div className="w-20 border-r border-black text-center">신청인</div>
        <div className="w-32 border-r border-black text-center">전화번호</div>
        <div className="w-20 border-r border-black text-center">PID</div>
        <div className="w-16 border-r border-black text-center">보명</div>
        <div className="w-16 border-r border-black text-center">부친</div>
        <div className="w-16 border-r border-black text-center">조부</div>
        <div className="w-36 border-r border-black text-center">신청일</div>
      </div>
    </div>
  )
}

function RegisterListItem({
  register,
  fetchRegisters,
}: {
  register: Register
  fetchRegisters: () => Promise<void>
}) {
  const { open, close } = useModalStore((state) => state)
  const approveRegister = async (userInfo: EditUser) => {
    try {
      await familyAPI.patch(`/admin/registers/${register.id}/approve`, userInfo)
      fetchRegisters()
      close()
    } catch {}
  }

  const rejectRegister = async () => {
    try {
      await familyAPI.patch(`/admin/registers/${register.id}/reject`)
      fetchRegisters()
      close()
    } catch {}
  }

  return (
    <div
      className="border-black border border-b-0 last:border-b hover:bg-gray-200 last:rounded-b-md cursor-pointer"
      onClick={() => {
        open(
          <HandleUser
            user={{
              ...register,
              expiredAt: '',
              status: UserStatus.ACTIVE,
              id: undefined,
            }}
            onSubmitUser={approveRegister}
            rejectable
            onReject={rejectRegister}
          />,
          '유저 추가'
        )
      }}
    >
      <div className="flex">
        <div className="w-20 border-r border-black text-center">
          {register.name}
        </div>
        <div className="w-32 border-r border-black text-center">
          {register.phoneNumber}
        </div>
        <div className="w-20 border-r border-black text-center">
          {register.pid}
        </div>
        <div className="w-16 border-r border-black text-center">
          {register.pedigreeName}
        </div>
        <div className="w-16 border-r border-black text-center">
          {register.dadName}
        </div>
        <div className="w-16 border-r border-black text-center">
          {register.grandDadName}
        </div>
        <div className="w-36 border-r border-black text-center">
          {formatDateTime(register.createdAt)}
        </div>
      </div>
    </div>
  )
}
