import { Link } from 'react-router-dom'

export function Menu() {
  return (
    <nav className="bg-cyan-900 min-h-screen w-44 text-white">
      <div className="p-3 text-xl text-center font-bold">행산 어드민</div>
      <ul>
        {menuItems.map((menuItem) => {
          return (
            <li key={menuItem.name}>
              <Link to={menuItem.href}>
                <div className="p-3">{menuItem.name}</div>
              </Link>
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

const menuItems = [
  {
    name: '메뉴 관리',
    href: '/menus/manage',
  },
  {
    name: '유저 관리',
    href: '/users/manage',
  },
  {
    name: '가입 관리',
    href: '/registers/manage',
  },
  {
    name: '남자수단 관리',
    href: '/applications/man/manage',
  },
  {
    name: '여서수단 관리',
    href: '/applications/daughter/manage',
  },
  {
    name: '관리자 관리',
    href: '/admin/manage',
  },
]
