import { ReactNode } from 'react'

export function ApplicationRow({
  title,
  children,
}: {
  title: string
  children?: ReactNode
}) {
  return (
    <div className="flex border-b">
      <div className="basis-24 shrink-0 flex items-center justify-center bg-gray-500 text-white">
        {title}
      </div>
      <div className="flex flex-1 justify-between shrink-0">
        <div className="flex flex-1">{children}</div>
      </div>
    </div>
  )
}
