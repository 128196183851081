import { useEffect, useState } from 'react'
import { familyAPI } from '../utils/API'
import { Page } from '../interfaces/Page'
import { useParams } from 'react-router-dom'
import { PageEditor } from '../components/editor/PageEditor'

export function ManagePage() {
  const [page, setPage] = useState<Page>()
  const param = useParams()

  useEffect(() => {
    const fetchPage = async () => {
      if (param.menuId && param.order) {
        setPage({
          id: 0,
          name: '',
          title: '',
          subTitle: '',
          titleColor: '',
          href: '',
          contents: '',
          order: +param.order,
          bannerImage: null,
          bannerBackgroundColor: '',
          isEditable: true,
          isPublished: false,
          menuId: +param.menuId,
        })

        return
      }
      try {
        const res = await familyAPI.get<Page>(`/sites/pages/${param.href}`)
        setPage(res.data)
      } catch {
        console.log('error')
      }
    }

    fetchPage()
  }, [param.href, param.menuId, param.order])

  if (page)
    return (
      <div>
        <PageEditor page={page} />
      </div>
    )
  return <div>페이지 관리</div>
}
