import { DateTime } from 'luxon'

export function formatDateTime(time: string) {
  if (time) {
    const dateTime = DateTime.fromISO(time)

    return dateTime.toFormat('yyyy/LL/dd HH:mm')
  }

  return ''
}

export function formatDate(time: string) {
  if (time) {
    const dateTime = DateTime.fromISO(time)

    return dateTime.toFormat('yyyy/LL/dd HH:mm')
  }

  return ''
}

export function formatDateCompact(time: string) {
  if (time) {
    const dateTime = DateTime.fromISO(time)

    return dateTime.toFormat('yyLLdd')
  }

  return ''
}
