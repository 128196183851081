import { create } from 'zustand'
import { Me } from '../interfaces/Me'
import { familyAPI, setTokenHeader } from '../utils/API'

interface MeStore {
  me: Me | null
  login: (token: string) => Promise<void>
  logout: () => void
}

export const useMeStore = create<MeStore>((set) => {
  const logout = () => {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')

    set({ me: null })
  }

  const login = async (token: string) => {
    try {
      const res = await familyAPI.get<Me>('/admin/me', {
        headers: { Authorization: `Bearer ${token}` },
      })

      setTokenHeader(token)
      set({ me: res.data })
    } catch {
      logout()
    }
  }

  return {
    me: null,
    login: login,
    logout: logout,
  }
})
