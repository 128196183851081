import {
  Card,
  List,
  ListItem,
  ListItemSuffix,
  Typography,
} from '@material-tailwind/react'
import { useEffect, useState } from 'react'
import { familyAPI } from '../utils/API'
import { Admin } from '../interfaces/Admin'
import { Paginated } from '../interfaces/Paginated'

export default function ManageAdmin() {
  const [admins, setAdmins] = useState<Admin[]>([])

  useEffect(() => {
    const setData = async () => {
      const res = await familyAPI.get<Paginated<Admin>>('/admin', {
        params: {
          page: 0,
          size: 5,
        },
      })
      setAdmins(res.data.items)
    }

    setData()
  }, [])
  return (
    <Card className="m-3">
      <List>
        {admins.map((admin) => (
          <AdminListItem key={admin.id} admin={admin} />
        ))}
        <ListItem ripple={false} className="">
          <div>
            <Typography variant="h6" color="blue-gray">
              + 관리자 추가
            </Typography>
          </div>
        </ListItem>
      </List>
    </Card>
  )
}

function AdminListItem({ admin }: { admin: Admin }) {
  return (
    <ListItem ripple={false} className="">
      <div>
        <Typography variant="h6" color="blue-gray">
          {admin.name}
        </Typography>
      </div>
      <ListItemSuffix>
        <i className="fas fa-heart" />
      </ListItemSuffix>
    </ListItem>
  )
}
