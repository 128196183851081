import {
  Button,
  Card,
  CardBody,
  CardFooter,
  IconButton,
  Input,
  Typography,
} from '@material-tailwind/react'
import { FormEvent, useCallback, useEffect, useState } from 'react'
import { User, UserStatus } from '../interfaces/User'
import { familyAPI } from '../utils/API'
import { useModalStore } from '../stores/ModalStore'
import { formatDateCompact } from '../utils/TimeFormatter'
import { EditUser, HandleUser } from '../components/modal/HandleUser'

export default function ManageUser() {
  const { open } = useModalStore((state) => state)
  const initSize = 30
  const [data, setData] = useState<{
    users: User[]
    startPage: number
    endPage: number
  }>({ users: [], startPage: 0, endPage: 0 })
  const [pageInfo, setPageInfo] = useState({
    page: 0,
    size: initSize,
  })
  const [searchInfo, setSearchInfo] = useState<{
    name: string
    phoneNumber: string
  }>({
    name: '',
    phoneNumber: '',
  })

  const fetchUsers = useCallback(
    async (page: number, size: number, name?: string, phoneNumber?: string) => {
      const res = await familyAPI.get('/admin/users', {
        params: {
          page: page,
          size: size,
          name: name,
          phoneNumber: phoneNumber,
        },
      })

      const startPage = Math.max(page - 2, 0)
      const endPage = Math.min(startPage + 4, res.data.total / size)

      setData({
        users: res.data.items,
        startPage: startPage,
        endPage: endPage,
      })
    },
    []
  )

  useEffect(() => {
    fetchUsers(0, initSize)
  }, [fetchUsers])

  const handleSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const name = searchInfo.name || undefined
    const phoneNumber = searchInfo.phoneNumber || undefined
    fetchUsers(pageInfo.page, pageInfo.size, name, phoneNumber)
  }

  const handleAddUser = async (userInfo: EditUser) => {
    await familyAPI.post('/admin/users', userInfo)
    window.location.reload()
  }

  const handleChangePage = (page: number) => {
    const name = searchInfo.name || undefined
    const phoneNumber = searchInfo.phoneNumber || undefined
    setPageInfo({ ...pageInfo, page: page })
    fetchUsers(page, pageInfo.size, name, phoneNumber)
  }

  const pagination = () => {
    const arr: Array<JSX.Element> = []
    for (let i = data.startPage; i < data.endPage; i++) {
      const variant = i === pageInfo.page ? 'filled' : 'text'
      arr.push(
        <IconButton
          key={i}
          variant={variant}
          onClick={() => handleChangePage(i)}
        >
          {i + 1}
        </IconButton>
      )
    }
    return arr
  }

  return (
    <>
      <Card className="m-2">
        <CardBody className="space-y-3">
          <Typography variant="h6" color="black">
            검색
          </Typography>
          <form className="flex gap-2" onSubmit={handleSearchSubmit}>
            <Input
              label="이름"
              onChange={(e) => {
                setSearchInfo({ ...searchInfo, name: e.target.value })
              }}
            />
            <Input
              label="전화번호"
              onChange={(e) => {
                setSearchInfo({ ...searchInfo, phoneNumber: e.target.value })
              }}
            />
            <Button className="w-24 bg-cyan-900" type="submit">
              검색
            </Button>
          </form>
          <Button
            onClick={() =>
              open(
                <HandleUser
                  user={{
                    id: 0,
                    name: '',
                    phoneNumber: '',
                    expiredAt: '',
                    status: UserStatus.ACTIVE,
                  }}
                  onSubmitUser={handleAddUser}
                />,
                '유저 추가'
              )
            }
          >
            유저 추가
          </Button>
        </CardBody>
      </Card>
      <Card className="m-2">
        <div className="mb-3 p-4">
          <UserListHeader />
          {data.users.map((user) => (
            <UserListItem key={user.id} user={user} />
          ))}
        </div>
        <CardFooter className="flex justify-center">
          <div className="flex items-center gap-2">
            <Button
              variant="text"
              className="flex items-center"
              onClick={() => handleChangePage(data.startPage)}
              disabled={pageInfo.page === 0}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-5 h-5"
              >
                <path
                  fillRule="evenodd"
                  d="M15.79 14.77a.75.75 0 01-1.06.02l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 111.04 1.08L11.832 10l3.938 3.71a.75.75 0 01.02 1.06zm-6 0a.75.75 0 01-1.06.02l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 111.04 1.08L5.832 10l3.938 3.71a.75.75 0 01.02 1.06z"
                  clipRule="evenodd"
                />
              </svg>
            </Button>
            <div className="flex items-center gap-1">{pagination()}</div>
            <Button
              variant="text"
              className="flex items-center"
              onClick={() => handleChangePage(data.endPage)}
              disabled={pageInfo.page === data.endPage}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-5 h-5"
              >
                <path
                  fillRule="evenodd"
                  d="M10.21 14.77a.75.75 0 01.02-1.06L14.168 10 10.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                  clipRule="evenodd"
                />
                <path
                  fillRule="evenodd"
                  d="M4.21 14.77a.75.75 0 01.02-1.06L8.168 10 4.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                  clipRule="evenodd"
                />
              </svg>
            </Button>
          </div>
        </CardFooter>
      </Card>
    </>
  )
}

function UserListHeader() {
  return (
    <div className="border-black border border-b-0 last:border-b last:rounded-b-md rounded-t-md bg-gray-300 font-bold">
      <div className="flex">
        <div className="w-20 border-r border-black text-center">ID</div>
        <div className="w-20 border-r border-black text-center">PID</div>
        <div className="w-20 border-r border-black text-center">이름</div>
        <div className="w-32 border-r border-black text-center">전화번호</div>
        <div className="w-32 border-r border-black text-center">등록일</div>
        <div className="w-32 border-r border-black text-center">만료일</div>
        <div className="w-20 border-r border-black text-center">상태</div>
      </div>
    </div>
  )
}

function UserListItem({ user }: { user: User }) {
  const { open } = useModalStore((state) => state)
  const handleEditUser = async (userInfo: EditUser) => {
    await familyAPI.put(`/admin/users/${user.id}`, userInfo)
    window.location.reload()
  }

  let userStatus = '입회'

  switch (user.status) {
    case UserStatus.BLOCKED:
      userStatus = '차단'
      break

    case UserStatus.EXPIRED:
      userStatus = '만료'
      break

    default:
      break
  }

  return (
    <div
      className="border-black border border-b-0 last:border-b hover:bg-gray-200 cursor-pointer last:rounded-b-md"
      onClick={() =>
        open(
          <HandleUser user={{ ...user }} onSubmitUser={handleEditUser} />,
          '유저 관리'
        )
      }
    >
      <div className="flex">
        <div className="w-20 border-r border-black text-center">{user.id}</div>
        <div className="w-20 border-r border-black text-center">{user.pid}</div>
        <div className="w-20 border-r border-black text-center">
          {user.name}
        </div>
        <div className="w-32 border-r border-black text-center">
          {user.phoneNumber}
        </div>
        <div className="w-32 border-r border-black text-center">
          {formatDateCompact(user.createdAt)}
        </div>
        <div className="w-32 border-r border-black text-center">
          {user.expiredAt}
        </div>
        <div className="w-20 border-r border-black text-center">
          {userStatus}
        </div>
      </div>
    </div>
  )
}
