import { Menu } from './Menu'
import { Header } from './Header'
import { Outlet } from 'react-router-dom'
import { Modal } from './Modal'

export function Layout() {
  return (
    <div className="flex min-h-screen w-screen">
      <Modal />
      <Menu />
      <main className="flex-1">
        <Header />
        <Outlet />
      </main>
    </div>
  )
}
