import {
  Button,
  Card,
  CardBody,
  CardFooter,
  IconButton,
  Input,
  List,
  ListItem,
  ListItemSuffix,
  Typography,
} from '@material-tailwind/react'
import { FormEvent, ReactNode, useCallback, useEffect, useState } from 'react'
import { Application } from '../interfaces/Application'
import { familyAPI } from '../utils/API'
import { formatDateTime } from '../utils/TimeFormatter'

export default function ManageDaughterApplications() {
  const initSize = 10
  const [data, setData] = useState<{
    applications: Array<Application>
    startPage: number
    endPage: number
  }>({ applications: [], startPage: 0, endPage: 0 })
  const [pageInfo, setPageInfo] = useState({
    page: 0,
    size: initSize,
  })
  const [searchInfo, setSearchInfo] = useState<{
    writer: string
    phoneNumber: string
  }>({
    writer: '',
    phoneNumber: '',
  })

  const fetchApplications = useCallback(
    async (
      page: number,
      size: number,
      writer?: string,
      phoneNumber?: string
    ) => {
      const res = await familyAPI.get('/admin/applications/daughter', {
        params: {
          page: page,
          size: size,
          writer: writer,
          phoneNumber: phoneNumber,
        },
      })

      const startPage = Math.max(page - 2, 0)
      const endPage = Math.min(startPage + 4, res.data.total / size)

      setData({
        applications: res.data.items,
        startPage: startPage,
        endPage: endPage,
      })
    },
    []
  )

  useEffect(() => {
    fetchApplications(0, initSize)
  }, [fetchApplications])

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const writer = searchInfo.writer || undefined
    const phoneNumber = searchInfo.phoneNumber || undefined
    fetchApplications(pageInfo.page, pageInfo.size, writer, phoneNumber)
  }

  const handleChangePage = (page: number) => {
    const writer = searchInfo.writer || undefined
    const phoneNumber = searchInfo.phoneNumber || undefined
    setPageInfo({ ...pageInfo, page: page })
    fetchApplications(page, pageInfo.size, writer, phoneNumber)
  }

  const pagination = () => {
    const arr: Array<JSX.Element> = []
    for (let i = data.startPage; i < data.endPage; i++) {
      const variant = i === pageInfo.page ? 'filled' : 'text'
      arr.push(
        <IconButton
          key={i}
          variant={variant}
          onClick={() => handleChangePage(i)}
        >
          {i + 1}
        </IconButton>
      )
    }
    return arr
  }

  return (
    <>
      <Card className="m-2">
        <CardBody className="space-y-3">
          <Typography variant="h6" color="black">
            검색
          </Typography>
          <form className="flex gap-2" onSubmit={handleSubmit}>
            <Input
              label="이름"
              onChange={(e) => {
                setSearchInfo({ ...searchInfo, writer: e.target.value })
              }}
            />
            <Input
              label="전화번호"
              onChange={(e) => {
                setSearchInfo({ ...searchInfo, phoneNumber: e.target.value })
              }}
            />
            <Button className="w-24 bg-cyan-900" type="submit">
              검색
            </Button>
          </form>
        </CardBody>
      </Card>
      <Card className="m-2">
        <List className="h-[960px] mb-3">
          {data.applications.map((application) => (
            <ApplicationListItem
              key={application.id}
              application={application}
            />
          ))}
        </List>
        <CardFooter className="flex justify-center">
          <div className="flex items-center gap-2">
            <Button
              variant="text"
              className="flex items-center"
              onClick={() => handleChangePage(data.startPage)}
              disabled={pageInfo.page === 0}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-5 h-5"
              >
                <path
                  fillRule="evenodd"
                  d="M15.79 14.77a.75.75 0 01-1.06.02l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 111.04 1.08L11.832 10l3.938 3.71a.75.75 0 01.02 1.06zm-6 0a.75.75 0 01-1.06.02l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 111.04 1.08L5.832 10l3.938 3.71a.75.75 0 01.02 1.06z"
                  clipRule="evenodd"
                />
              </svg>
            </Button>
            <div className="flex items-center gap-1">{pagination()}</div>
            <Button
              variant="text"
              className="flex items-center"
              onClick={() => handleChangePage(data.endPage)}
              disabled={pageInfo.page === data.endPage}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-5 h-5"
              >
                <path
                  fillRule="evenodd"
                  d="M10.21 14.77a.75.75 0 01.02-1.06L14.168 10 10.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                  clipRule="evenodd"
                />
                <path
                  fillRule="evenodd"
                  d="M4.21 14.77a.75.75 0 01.02-1.06L8.168 10 4.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                  clipRule="evenodd"
                />
              </svg>
            </Button>
          </div>
        </CardFooter>
      </Card>
    </>
  )
}

function ApplicationListItem({ application }: { application: Application }) {
  return (
    <ListItem className="border-black border">
      <div className="w-full">
        <div className="flex justify-between">
          <div className="flex space-x-3 flex-1">
            <Col title="작성자">{application.writer}</Col>
            <Col title="전화번호">{application.phone}</Col>
            <LargeCol title="계통">{`${application.greatGrandDad}_${application.grandDad}_${application.dad}_${application.name}`}</LargeCol>
          </div>
          <div className="flex space-x-3">
            <Col title="메모">{application.memo ? '✅' : '❌'}</Col>
            <Col title="최초작성일">
              {formatDateTime(application.createdAt)}
            </Col>
            <Col title="최종수정일">
              {formatDateTime(application.updatedAt)}
            </Col>
          </div>
        </div>
      </div>
      <ListItemSuffix>
        <></>
      </ListItemSuffix>
    </ListItem>
  )
}

function Col({ title, children }: { title: string; children: ReactNode }) {
  return (
    <div className="w-24">
      <Typography variant="h6" color="blue-gray">
        {title}
      </Typography>
      <div>{children}</div>
    </div>
  )
}

function LargeCol({ title, children }: { title: string; children: ReactNode }) {
  return (
    <div className="flex-1">
      <Typography variant="h6" color="blue-gray">
        {title}
      </Typography>
      <div>{children}</div>
    </div>
  )
}
