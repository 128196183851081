import { familyAPI } from '../../utils/API'

export function UploadPlugin(editor: any) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
    return new CustomUploadAdapter(loader)
  }
}

class CustomUploadAdapter {
  loader: any
  constructor(loader: any) {
    this.loader = loader
  }

  upload() {
    return this.loader.file.then(
      (file: any) =>
        new Promise((resolve: any, reject: any) => {
          const config = {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }

          const formData = new FormData()
          formData.append('file', file)

          familyAPI
            .post('/admin/images', formData, config)
            .then((res) => {
              resolve({
                default: res.data.url,
              })
            })
            .catch((error) => {
              console.log(error)
              this.abort()
            })
        })
    )
  }

  abort() {
    console.log('aborted')
  }
}
