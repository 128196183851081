import { useEffect } from 'react'

export const useKeyDown = (keyDownAction: () => void, key: string) => {
  useEffect(() => {
    const escKeyModalClose = (e: KeyboardEvent) => {
      if (e.key === key) {
        keyDownAction()
      }
    }
    window.addEventListener('keydown', escKeyModalClose)
    return () => window.removeEventListener('keydown', escKeyModalClose)
  }, [keyDownAction, key])
}
