import { ReactNode } from 'react'
import { create } from 'zustand'

interface ModalStore {
  children: ReactNode | null
  title?: string
  isOpen: boolean
  open: (children: ReactNode, title: string) => void
  close: () => void
}

export const useModalStore = create<ModalStore>((set) => {
  const open = (children: ReactNode, title: string) => {
    set({ children: children, title: title, isOpen: true })
  }

  const close = () => {
    set({ children: null, title: undefined, isOpen: false })
  }

  return {
    children: null,
    title: undefined,
    isOpen: false,
    open: open,
    close: close,
  }
})
