import { useKeyDown } from '../hooks/useKeyDown'
import { useModalStore } from '../stores/ModalStore'

export function Modal() {
  const { title, children, isOpen, close } = useModalStore((state) => state)
  useKeyDown(close, 'Escape')

  if (isOpen)
    return (
      <div
        className="absolute left-0 top-0 z-10 bg-black h-full w-full bg-opacity-60 flex items-center justify-center"
        onClick={(e) => {
          if (e.target === e.currentTarget) close()
        }}
      >
        <div className="bg-white rounded -mt-80">
          <div className="flex justify-between p-2">
            <div>{title}</div>
            <button className="w-6" onClick={() => close()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z" />
              </svg>
            </button>
          </div>
          <div className="p-2">{children}</div>
        </div>
      </div>
    )
}
