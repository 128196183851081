import { Route, Routes } from 'react-router-dom'
import { Layout } from './components/Layout'
import { ManageMenu } from './pages/ManageMenu'
import { ManagePage } from './pages/MangePage'
import Login from './pages/Login'
import { useMeStore } from './stores/MeStore'
import { useEffect, useState } from 'react'
import ManageAdmin from './pages/ManageAdmin'
import ManageRegister from './pages/ManageRegister'
import ManageUser from './pages/ManageUser'
import ManageManApplications from './pages/ManageManApplications'
import ManageDaughterApplications from './pages/ManageDaughterApplications'
import ManageManApplication from './pages/ManageManApplication'

function App() {
  const { me, login } = useMeStore((state) => state)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const token = localStorage.getItem('accessToken')

    const init = async () => {
      if (token) await login(token)
      setIsLoading(false)
    }

    init()
  }, [login])

  if (isLoading) return <div>loading...</div>
  else if (me)
    return (
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<ManageMenu />} />
          <Route path="/menus/manage" element={<ManageMenu />} />
          <Route
            path="/menus/:menuId/:order/new-page"
            element={<ManagePage />}
          />
          <Route path="/pages/:href/manage" element={<ManagePage />} />
          <Route path="/admin/manage" element={<ManageAdmin />} />
          <Route path="/registers/manage" element={<ManageRegister />} />
          <Route path="/users/manage" element={<ManageUser />} />
          <Route
            path="/applications/man/manage"
            element={<ManageManApplications />}
          />
          <Route
            path="/applications/daughter/manage"
            element={<ManageDaughterApplications />}
          />
          <Route
            path="/applications/man/:id"
            element={<ManageManApplication />}
          />
        </Route>
      </Routes>
    )
  else return <Login />
}

export default App
