export interface User {
  id: number
  pid: number
  name: string
  phoneNumber: string
  status: UserStatus
  createdAt: string
  updatedAt: string
  expiredAt: string
}

export enum UserStatus {
  ACTIVE = 'active',
  BLOCKED = 'blocked',
  EXPIRED = 'expired',
}
