import axios from 'axios'
import { useMeStore } from '../stores/MeStore'

const createAPIClient = (baseURL?: string) => {
  const client = axios.create({ baseURL: baseURL })

  client.interceptors.response.use(
    (response) => response,
    async (error) => {
      const refreshToken = localStorage.getItem('refreshToken')

      if (
        error.config &&
        error.response &&
        error.response.status === 401 &&
        refreshToken
      ) {
        try {
          const res = await familyAPI.post('/admin/reissue', {
            token: refreshToken,
          })

          localStorage.setItem('accessToken', res.data.accessToken)
          localStorage.setItem('refreshToken', res.data.refreshToken)

          setTokenHeader(res.data.accessToken)
          error.config.headers.Authorization = `Bearer ${res.data.accessToken}` // for retry set config's auth header

          return axios.request(error.config)
        } catch {
          useMeStore.getState().logout()
        }
      }

      return Promise.reject(error)
    }
  )

  return client
}

export const setTokenHeader = (token: string) => {
  familyAPI.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

export const familyAPI = createAPIClient(process.env.REACT_APP_FAMILY_API_URL)
