import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { familyAPI } from '../utils/API'
import { ManApplication } from '../interfaces/ManApplication'
import { Row } from '../components/application/Row'
import { Title } from '../components/application/Title'
import { Label } from '../components/application/Label'
import { ApplicationRow } from '../components/application/ApplicationRow'
import { WifeApplication } from '../interfaces/WifeApplication'
import Element from '../components/application/Element'
import { Button, Switch } from '@material-tailwind/react'
import { Input } from '../components/application/Input'

export default function ManageManApplication() {
  const params = useParams()
  const [application, setApplication] = useState<ManApplication>()
  const [applicationInfo, setApplicationInfo] = useState({
    memo: '',
    cost: false,
  })

  useEffect(() => {
    const fetchApplication = async () => {
      try {
        const res = await familyAPI.get<ManApplication>(
          `/admin/applications/man/${params.id}`
        )

        setApplication(res.data)
      } catch {
        console.log('error')
      }
    }

    fetchApplication()
  }, [params.id])

  if (!application) return <div>not found!</div>

  return (
    <div className="p-10">
      <Row>
        <Title>문중</Title>
        <div className="flex justify-between flex-1">
          <div className="flex w-96">
            <Element>{application.clan1}</Element>
            <Element>{application.clan2}</Element>
          </div>
        </div>
      </Row>
      <Row>
        <Title>작성자</Title>
        <div className="flex-1">
          <Row>
            <Label>이름</Label>
            <Element>{application.writer}</Element>
            <Label>H.P</Label>
            <Element>{application.phone}</Element>
            <Label>집전화</Label>
            <Element>{application.tel}</Element>
          </Row>
          <Row>
            <Label>주소</Label>
            <Element>{application.address}</Element>
          </Row>
        </div>
      </Row>
      <div className="flex border-b-2 border-gray-300">
        <div className="w-36">
          <div className="bg-gray-700 text-white p-2 text-center">항목</div>
        </div>
        <div className="flex-1">
          <div className="bg-gray-700 text-white p-2 text-center">수단작성</div>
        </div>
      </div>
      <div className="flex">
        <div className="w-12 p-2 text-center border-b border-r flex items-center bg-gray-700 text-white">
          淵源
        </div>
        <div className="flex-1">
          <ApplicationRow title="증조(曾祖)">
            <div className="flex w-96">
              <Label>漢字</Label>
              <Element>{application.greatGrandDad}</Element>
              <Label>한글</Label>
              <Element>{application.greatGrandDadKor}</Element>
            </div>
          </ApplicationRow>
          <ApplicationRow title="조부(祖父)">
            <div className="flex w-96">
              <Label>漢字</Label>
              <Element>{application.grandDad}</Element>
              <Label>한글</Label>
              <Element>{application.grandDadKor}</Element>
            </div>
          </ApplicationRow>
          <ApplicationRow title="친부(親父)">
            <div className="flex w-96">
              <Label>漢字</Label>
              <Element>{application.dad}</Element>
              <Label>한글</Label>
              <Element>{application.dadKor}</Element>
            </div>
            <div className="flex items-center w-20 ml-2">
              <span>의 {application.order}子</span>
            </div>
          </ApplicationRow>
          <ApplicationRow title="계부(系父)">
            <div className="flex w-96">
              <Label>漢字</Label>
              <Element>{application.stepDad}</Element>
              <Label>한글</Label>
              <Element>{application.stepDadKor}</Element>
            </div>
            <div className="flex items-center w-20 ml-2">
              <span>의 {application.stepOrder}子</span>
            </div>
          </ApplicationRow>
        </div>
      </div>
      <div className="flex">
        <div className="w-12 bg-gray-700 text-white p-2 text-center border-b border-r flex items-center">
          本人
        </div>
        <div className="flex-1">
          <ApplicationRow title="世">
            <Label>杏山</Label>
            <div className="flex-1">
              <Element> {application.line}世</Element>
            </div>
          </ApplicationRow>
          <ApplicationRow title="보명(譜名)">
            <div className="flex w-96">
              <Label>漢字</Label>
              <Element>{application.name}</Element>
              <Label>한글</Label>
              <Element>{application.kor}</Element>
            </div>
          </ApplicationRow>
          <ApplicationRow title="별칭(別稱)">
            <div className="flex w-96">
              <Label>漢字</Label>
              <Element>{application.alias}</Element>
              <Label>한글</Label>
              <Element>{application.aliasKor}</Element>
            </div>
          </ApplicationRow>
          <ApplicationRow title="자(字)">
            <div className="flex w-96">
              <Label>漢字</Label>
              <Element>{application.ja}</Element>
              <Label>한글</Label>
              <Element>{application.jaKor}</Element>
            </div>
          </ApplicationRow>
          <ApplicationRow title="호(號)">
            <div className="flex w-96">
              <Label>漢字</Label>
              <Element>{application.ho}</Element>
              <Label>한글</Label>
              <Element>{application.hoKor}</Element>
            </div>
          </ApplicationRow>
          <ApplicationRow title="작위(爵位)">
            <div className="flex w-96">
              <Element>{application.honor}</Element>
            </div>
          </ApplicationRow>
          <ApplicationRow title="생일(陰)">
            <Element>
              {application.birthIsSelf
                ? application.birth
                : `${application.birthKing}${application.birthYear}${application.birthMonth}${application.birthDay}`}
            </Element>
          </ApplicationRow>
          <ApplicationRow title="졸일(陰)">
            <Element>
              {application.deathIsSelf
                ? application.death
                : `${application.deathKing}${application.deathYear}${application.deathMonth}${application.deathDay}`}
            </Element>
          </ApplicationRow>
          <ApplicationRow title="수">
            <Label>享年</Label>
            <Element>{application.age}</Element>
          </ApplicationRow>
          <ApplicationRow title="전기(傳記)">
            <Element>{application.record}</Element>
          </ApplicationRow>
          <ApplicationRow title="묘(墓)">
            <Element>{application.grave}</Element>
          </ApplicationRow>
        </div>
      </div>
      {application.wives.map((wifeApplication, idx) => (
        <ManageWifeApplication
          application={wifeApplication}
          key={idx}
          idx={idx}
        />
      ))}
      <form>
        <Row>
          <Title>메모</Title>
          <div className="flex flex-1 space-x-1">
            <Input
              name="memo"
              value={applicationInfo.memo}
              onChange={(e) => {
                setApplicationInfo({ ...applicationInfo, memo: e.target.value })
              }}
            />
            <Switch
              label="수단비"
              checked={applicationInfo.cost}
              onChange={(e) => {
                familyAPI.patch(`/admin/applications/man/${application.id}`, {
                  cost: e.target.checked,
                })

                setApplicationInfo({
                  ...applicationInfo,
                  cost: e.target.checked,
                })
              }}
              labelProps={{ className: 'font-normal text-black' }}
            />
          </div>
        </Row>
        <Row>
          <div className="flex flex-1 justify-end h-10 mt-1">
            <Button type="submit">저장</Button>
          </div>
        </Row>
      </form>
    </div>
  )
}

function ManageWifeApplication({
  application,
  idx,
}: {
  application: WifeApplication
  idx: number
}) {
  return (
    <div className="flex">
      <div className="w-12 bg-gray-700 text-white p-2 text-center border-b border-r flex flex-col justify-center items-center">
        配位{idx + 1}
      </div>
      <div className="flex-1">
        <ApplicationRow title="본관성씨">
          <Element>{application.family}</Element>
        </ApplicationRow>
        <ApplicationRow title="보명(譜名)">
          <div className="flex w-96">
            <Label>漢字</Label>
            <Element>{application.name}</Element>
            <Label>한글</Label>
            <Element>{application.kor}</Element>
          </div>
        </ApplicationRow>
        <ApplicationRow title="친정정보">
          <div className="flex w-96">
            <Element>{application.info}</Element>
          </div>
        </ApplicationRow>
        <ApplicationRow title="생일(陰)">
          <Element>
            {application.birthIsSelf
              ? application.birth
              : `${application.birthKing}${application.birthYear}${application.birthMonth}${application.birthDay}`}
          </Element>
        </ApplicationRow>
        <ApplicationRow title="졸일(陰)">
          <Element>
            {application.deathIsSelf
              ? application.death
              : `${application.deathKing}${application.deathYear}${application.deathMonth}${application.deathDay}`}
          </Element>
        </ApplicationRow>
        <ApplicationRow title="수">
          <Element>{application.age}</Element>
        </ApplicationRow>
        <ApplicationRow title="묘(墓)">
          <Element>{application.grave}</Element>
        </ApplicationRow>
      </div>
    </div>
  )
}
